import { Tag, Title } from '@maersk-global/apmt-react-components';
import { useTranslation } from 'react-i18next';

import { RemainingWorkInstructions } from '@/components/atoms/RemainingWorkInstructions/RemainingWorkInstructions';
import { SecondaryInfo } from '@/components/atoms/SecondaryInfo/SecondaryInfo';
import { WorkQueueKind, WorkQueueMode, WorkQueueType } from '@/constants/enums';
import { WorkQueues } from '@/types/instructions';

export const WorkQueueWrapper = ({
    workQueue,
    children,
}: {
    workQueue: WorkQueues;
    quayCraneName: string;
    children: React.ReactNode;
}) => {
    const { name, kind, instructionsLeft } = workQueue;

    const isSingleCycle = kind === WorkQueueKind.SINGLECYCLE;
    const isLoad = isSingleCycle && workQueue.loadType === WorkQueueType.LOAD;
    const isDischarge = isSingleCycle && workQueue.loadType === WorkQueueType.DISCHARGE;
    const isDualCycling = kind === WorkQueueKind.DUALCYCLE;

    const workQueueName = kind === WorkQueueKind.SINGLECYCLE ? name : name.join(' & ');
    return (
        <div className="flex flex-col gap-3">
            <WorkQueueTitle
                isDualCycling={isDualCycling}
                title={workQueueName}
                isDischarge={isDischarge}
                isLoad={isLoad}
                instructionsLeft={instructionsLeft}
                loadMode={workQueue.loadMode}
            />
            {children}
        </div>
    );
};
export const WorkQueueTitle = ({
    isDualCycling,
    title,
    isLoad,
    isDischarge,
    instructionsLeft,
    loadMode,
}: {
    isDualCycling: boolean;
    title: string;
    isLoad: boolean;
    isDischarge: boolean;
    loadMode: WorkQueueMode;
    instructionsLeft?: number;
}) => {
    const { t } = useTranslation();
    return (
        <Title className="mds-font--display-1 flex items-center gap-1">
            {isDualCycling ? (
                <>
                    <Tag
                        appearance="warning"
                        fit="small"
                        width="auto"
                        label={`${t('labels.dualCycling')}: ${loadMode.toLowerCase()}`}
                    />
                    <SecondaryInfo>{title}</SecondaryInfo>
                </>
            ) : (
                <>
                    {isLoad && (
                        <Tag
                            fit="small"
                            width="auto"
                            label={`${t('labels.loading')}: ${loadMode.toLowerCase()}`}
                        />
                    )}
                    {isDischarge && <Tag label="discharge" fit="small" width="auto" />}
                    {<SecondaryInfo>{title}</SecondaryInfo>}
                </>
            )}
            {instructionsLeft !== undefined ? (
                <RemainingWorkInstructions instructionsLeft={instructionsLeft} />
            ) : null}
        </Title>
    );
};
