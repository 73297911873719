export interface SharedRoutes {
    andon: (locale: string) => string;
    gate: {
        workstationSettings: (locale: string) => string;
        scenarioSettings: (locale: string) => string;
        home: (locale: string) => string;
    };
    controlOverview: (terminalId: string, locale: string) => string;
    shuttleCarrier: (locale: string) => string;
    terminalMap: (terminalId: string, locale: string) => string;
    flowOverview: (terminalId: string, locale: string) => string;
    yardOverview: (terminalId: string, locale: string) => string;
    settings: (terminalId: string, locale: string) => string;
}

export enum SharedSlugs {
    andon = '/andon',
    gate = '/gate/status',
    gateScenarioSettings = '/gate/scenarios',
    gateWorkstationSettings = '/gate/workstations',
    controlOverview = '/flow/control',
    terminalMap = '/map',
    flowOverview = '/flow/overview',
    yardOverview = '/yard/overview',
    shuttleCarrier = '/shuttle-carrier',
    mapEdit = '/map/edit',
    settings = '/settings',
}

export const isActiveRoute = (path: string, sharedSlug: SharedSlugs): boolean =>
    path.includes(sharedSlug);

export const sharedRoutes = (isAndon: boolean, isDev: boolean): SharedRoutes => {
    const dposPath = isDev && isAndon ? 'http://localhost:3001' : '';
    const andonPath = isDev && !isAndon ? 'http://localhost:3002' : '';
    const gatePath = isDev ? 'http://localhost:3004' : '';
    const shuttleCarrierPath = '/shell';

    return {
        andon: locale =>
            generateSlug({
                isExternal: true,
                path: andonPath,
                locale,
                slug: SharedSlugs.andon,
            }),
        shuttleCarrier: locale =>
            generateSlug({
                isExternal: true,
                path: shuttleCarrierPath,
                locale,
                slug: SharedSlugs.shuttleCarrier,
            }),
        gate: {
            home: locale =>
                generateSlug({
                    isExternal: true,
                    path: gatePath,
                    locale,
                    slug: SharedSlugs.gate,
                }),
            scenarioSettings: locale =>
                generateSlug({
                    isExternal: true,
                    path: gatePath,
                    locale,
                    slug: SharedSlugs.gateScenarioSettings,
                }),
            workstationSettings: locale =>
                generateSlug({
                    isExternal: true,
                    path: gatePath,
                    locale,
                    slug: SharedSlugs.gateWorkstationSettings,
                }),
        },
        controlOverview: (terminalId: string, locale) =>
            generateSlug({
                path: dposPath,
                locale,
                terminalId,
                slug: SharedSlugs.controlOverview,
            }),
        terminalMap: (terminalId: string, locale) =>
            generateSlug({
                path: dposPath,
                locale,
                terminalId,
                slug: SharedSlugs.terminalMap,
            }),
        flowOverview: (terminalId: string, locale) =>
            generateSlug({
                path: dposPath,
                locale,
                terminalId,
                slug: SharedSlugs.flowOverview,
            }),
        yardOverview: (terminalId: string, locale) =>
            generateSlug({
                path: dposPath,
                locale,
                terminalId,
                slug: SharedSlugs.yardOverview,
            }),
        settings: (terminalId: string, locale) =>
            generateSlug({
                path: dposPath,
                locale,
                terminalId,
                slug: SharedSlugs.settings,
            }),
    };
};

const generateSlug = ({
    path,
    slug,
    isExternal = false,
    locale: propsLocale,
    terminalId,
}: {
    path: string;
    slug: SharedSlugs;
    isExternal?: boolean;
    locale?: string;
    terminalId?: string;
}) => {
    const locale = propsLocale ? propsLocale : 'en';

    if (isExternal) {
        return `${path}/${locale}${slug}`;
    }

    return `${path}/${locale}/terminal/${terminalId}${slug}`;
};
