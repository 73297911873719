import { Just, Nothing } from 'purify-ts';

import { QuayCraneWorkQueueTableData } from '@/components/organisms/QuayCraneInstructionsTable';
import { FlowPositionName, WorkQueueKind, WorkQueueMode, WorkQueueType } from '@/constants/enums';
import { YardWork } from '@/store/yardStore';
import { AssignedPosition, WorkQueues, YardCheType, flowPositionNameShortLabel } from '@/types';

export type InstructionsPerWorkQueue = {
    name: string;
    work: QuayCraneWorkQueueTableData[];
    loadMode: WorkQueueMode;
    moveKind: WorkQueueType;
    isDualCycle: boolean;
    instructionsLeft: number;
}[];
export const toQuayCraneTableData = (
    workQueues: WorkQueues[],
    consideredCompletedTruckNames: string[],
    quayCraneName: string,
    includeYard: boolean,
    yardWork: YardWork,
    yardCheTypes: { [cheId: string]: YardCheType },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    t: (key: string, map?: Record<string, any>) => string,
    size: 'large' | 'small' = 'large',
): InstructionsPerWorkQueue => {
    const completedLabel =
        size === 'small'
            ? 'pages.positions.detailDrawer.consideredCompleteInstructionShort'
            : 'pages.positions.detailDrawer.consideredCompleteInstruction';
    return workQueues.map(wq => {
        const quayCraneWorkQueueTableData: QuayCraneWorkQueueTableData[] = [];
        const wqIds =
            wq.kind === WorkQueueKind.SINGLECYCLE ? [wq.id] : wq.idsAndLoadType.map(({ id }) => id);
        wq.instructions.forEach(i => {
            const consideredComplete = consideredCompletedTruckNames.includes(i.truckId);
            const instructionTranslation = consideredComplete
                ? t(completedLabel)
                : flowPositionNameShortLabel(i.instruction);
            quayCraneWorkQueueTableData.push({
                instruction: instructionTranslation,
                hasReachedFlowPosition: Just(i.hasReachedFlowPosition)
                    .filter(() => i.instruction !== FlowPositionName.PULL)
                    .filter(() => !consideredComplete),
                isLocked: Just(i.isLocked ?? false)
                    .filter(() => i.instruction !== FlowPositionName.PULL)
                    .filter(() => !consideredComplete),
                truckName: i.truckId,
                handledCraneTriggers:
                    wq.kind === WorkQueueKind.DUALCYCLE ? i.handledCraneTriggers : undefined,
                expectedCraneTriggers:
                    wq.kind === WorkQueueKind.DUALCYCLE ? i.expectedCraneTriggers : undefined,
                originalInstruction: i.instruction,
                to: quayCraneName,
                consideredComplete,
                kind: 'QC',
            });
        });
        quayCraneWorkQueueTableData.sort((a, b) => {
            if (a.consideredComplete) {
                return -1;
            }
            if (b.consideredComplete) {
                return 1;
            }
            if (a.originalInstruction === FlowPositionName.UNDER_CRANE) {
                return -1;
            }
            if (b.originalInstruction === FlowPositionName.UNDER_CRANE) {
                return 1;
            }
            if (a.originalInstruction === FlowPositionName.STANDBY) {
                return -1;
            }
            if (b.originalInstruction === FlowPositionName.STANDBY) {
                return 1;
            }

            return 0;
        });
        if (includeYard) {
            Object.entries(yardWork).forEach(([cheId, work]) => {
                work.forEach(w => {
                    if (w.workQueueId === undefined || !wqIds.includes(w.workQueueId)) {
                        return;
                    }
                    let instruction = '';
                    const cheType = yardCheTypes[cheId] ?? YardCheType.UNKNOWN;
                    switch (w.flowPosition) {
                        case FlowPositionName.PULL:
                        case FlowPositionName.STANDBY:
                        case AssignedPosition.YARD_BLOCK:
                        case AssignedPosition.STAGING:
                            instruction = t(`pages.positions.yard.${w.flowPosition}`);
                            break;
                        default:
                            instruction = t(
                                `pages.yard.jobTitleDrawer.flowPosition.underCHE.${cheType}`,
                            );
                    }
                    quayCraneWorkQueueTableData.push({
                        instruction,
                        hasReachedFlowPosition: Nothing,
                        isLocked: Nothing,
                        originalInstruction: w.flowPosition,
                        truckName: w.truck,
                        to: cheId,
                        consideredComplete: false,
                        kind: 'RTG',
                        handledCraneTriggers: undefined,
                        expectedCraneTriggers: undefined,
                        rowClassName: 'bg-neutral-100',
                    });
                });
            });
        }
        return {
            name: wq.kind === WorkQueueKind.SINGLECYCLE ? wq.name : wq.name.join('&'),
            work: quayCraneWorkQueueTableData,
            loadMode: wq.loadMode,
            moveKind: wq.kind === WorkQueueKind.SINGLECYCLE ? wq.loadType : WorkQueueType.DISCHARGE,
            isDualCycle: wq.kind === WorkQueueKind.DUALCYCLE,
            instructionsLeft: wq.instructionsLeft,
        };
    });
};
